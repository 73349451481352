import React, { useContext, useState, useEffect } from 'react';
//import { Link } from 'gatsby';
import { Swiper, SwiperSlide } from "swiper/react";
import waifuMintIcon from "../../assets/vector/waifuMintIcons/waifuMintIcon.svg";

import UserContext from "../../context/userContext/UserContext";
import Card from '../elements/maintenance/damagedTractorCard';
//import dialogWaifu from "../../assets/img/dialogWaifu2.svg";
//import mechanicWaifu from "../../assets/img/mechanicSection/mechanicWaifu.webp";
import contractAddress from "../../utils/Contract.json"
import Loading from '../Loading/Loading';
import Loader from '../../components/elements/ModalX/loader';

import HelpTractors from '../../helpers/tractors';

import peachIcon from "../../assets/img/pechicon2.svg";

export default function Mechanic() {

    const { contract, account } = useContext(UserContext)
    const [tractorRepair, setTractorRepair] = useState(null)
    const [loading, setLoading] = useState(false)
    const [allowance, setAllowance] = useState(false);
    const [updateData, setUpdateData] = useState(false);
    const [peachPrice, setPricePeach] = useState(0);

    useEffect(() => {
        if (contract && account) {

            contract.contractTractor.methods.getTractorIdsOf(account).call().then(result => {

                const getAllTractor = []

                result.map(element => { if (element !== "1000000000000000000") return getAllTractor.push(contract.contractTractor.methods.getTractor(element).call()) })
                Promise.all(getAllTractor).then(result => {
                    const listAllTractor = []
                    result.reverse().map(element => {

                        let timeNow = new Date()
                        let brokenLeft = element.brokenUntil - (timeNow.getTime() / 1000)
                        if (brokenLeft > 0) { listAllTractor.push(element) }

                    })
                    setTractorRepair(listAllTractor)

                }).catch(err => setTractorRepair([]))
            });

            contract.contractPeach.methods.getCurrentPrice().call({ from: account }).then((result) => {
                setPricePeach(result);
            }).catch(err => console.error(err));
        }
    }, [contract, account, updateData]);

    useEffect(() => {
        if (contract) contract.contractPeach.methods.allowance(account, process.env.GATSBY_DWGAME).call().then(result => parseInt(result) > 0 && setAllowance(true)).catch(err => console.error(err))
    }, [contract])



    const repairTractor = (element) => {

        return new Promise((resolve, reject) => {

            if (contract) {
                setLoading(true)

                if (!allowance) {
                    contract.contractPeach.methods.approve(process.env.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account }).then(result => {
                        contract.contractDWGameTractor.methods.repair(element.id).send({ from: account, value: 2 + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); resolve(true); }).catch(err => reject(false));
                    }).catch(err => { console.error(err); reject(false); })
                }
                else contract.contractDWGameTractor.methods.repair(element.id).send({ from: account, value: 2 + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); resolve(true); }).catch(err => reject(false));

            }

        })

    }

    const multiRepairTractor = () => {
        const list_repair_tractor = HelpTractors.getTractorRepair(tractorRepair);

        if (list_repair_tractor.length === 0) return;

        if (contract) {
            setLoading(true)

            if (!allowance) {
                contract.contractPeach.methods.approve(contractAddress.GATSBY_DWGAME, 5 + '0'.repeat(24)).send({ from: account }).then(result => {
                    contract.contractDWGameTractor.methods.multiRepair(list_repair_tractor).send({ from: account, value: (2 * list_repair_tractor.length) + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); setLoading(false); }).catch(err => setLoading(false));
                }).catch(err => { setLoading(false); })
            }
            else contract.contractDWGameTractor.methods.multiRepair(list_repair_tractor).send({ from: account, value: (2 * list_repair_tractor.length) + '0'.repeat(15) }).then(result => { setUpdateData(!updateData); setLoading(false); }).catch(err => setLoading(false));
        }
    }

    return (
        <div className="relative py-5">

            {!tractorRepair ? <div className="h-80 flex justify-center items-center"><Loader /></div>

                : <>{tractorRepair.length > 0
                    ? <div className="px-4">
                        <div className="flex justify-between mb-2 items-center flex-col md:flex-row">
                            <div className="text-darkPink text-2xl font-semibold hidden md:block mix-blend-color-burn">Damaged Tractors</div>
                            <div className="text-darkPink border-darkPink border-2 rounded-lg bg-pink-200 bg-opacity-70 p-2 px-4 flex items-center h-12">
                                <div className="text-md md:text-lg font-semibold mr-2 flex items-center">
                                    <div>{HelpTractors.getMPriceTractors(tractorRepair, peachPrice)}</div>
                                    <img src={peachIcon} className="w-6 ml-2" />
                                </div>
                                <button className="text-white font-semibold md:font-bold rounded-full p-1 px-3 bg-darkPink" onClick={multiRepairTractor}>Heal All</button>
                            </div>
                        </div>
                        <div className="bg-primary overflow-x-auto overflow-y-hidden flex w-full p-4 rounded-tr-lg rounded-br-lg rounded-bl-lg m-auto mb-12 ">

                            <Swiper
                                breakpoints={{

                                    1250: { slidesPerView: 4 },
                                    900: { slidesPerView: 3 },
                                    600: { slidesPerView: 2 },
                                    200: { slidesPerView: 1 }

                                }}
                                spaceBetween={10}
                                pagination={{ clickable: true, }}
                                className="mySwiper"
                            >
                                {tractorRepair?.map(element => <SwiperSlide> <Card setLoading={setLoading} repairTractor={repairTractor} damanger={"Repair"} element={element} peachPrice={peachPrice} />  </SwiperSlide>)}

                            </Swiper>
                        </div>
                    </div>

                    : <div className="grid grid-rows-mint justify-center text-center mt-16">
                        <img src={waifuMintIcon} alt="waifu havent mint" className="w-44 mx-auto self-center"></img>
                        <span className="font-bold text-base text-black">It seems that you don't have any damage tractor at this moment</span>

                    </div>



                }

                </>





            }
            <Loading modal={loading} setModal={setLoading} />

        </div >

    )
}

/* 

        <div>
            <div className="bg-primary flex w-72 justify-center mt-24 mx-auto p-2 rounded-t-lg lg:ml-16 lg:text-xl lg:w-80">
                <h2 className="text-primaryDark font-bold text-lg">Damaged Tractors</h2>
                <button className="bg-buttonBuy text-backgroundOp py-1 px-2 rounded-lg ml-2"> <strong>REPAIR ALL</strong> </button>
            </div>
            <div className="bg-primary overflow-x-auto overflow-y-hidden flex w-80 p-4 rounded-lg m-auto mb-12 lg:w-3/4 lg:ml-8">
                <Card damanger={"Damanger"} />
                <Card damanger={"Damanger"} />
                <Card damanger={"Damanger"} />
                <Card damanger={"Damanger"} />
            </div>
        </div>

*/