import React from 'react';

const getTractorRepair = (tractorRepair) => {
    const list_repair_tractor = tractorRepair.filter(e => {
        let a = new Date().getTime();
        let b = e.brokenUntil - (a / 1000);
        if (b > 43200) return true;
        return false;
    }).map(e => { return e.id });

    return list_repair_tractor;
}

const getMPriceTractors = (tractorRepair, peachPrice) => {
    const list_repair_tractor = tractorRepair.filter(e => {
        let a = new Date().getTime();
        let b = e.brokenUntil - (a / 1000);
        if (b > 43200) return true;
        return false;
    }).map(e => {
        let starTractor = parseInt(e.level);
        if (starTractor === 1) return 2.40 / (peachPrice / 1000);
        if (starTractor === 2) return 3.00 / (peachPrice / 1000);
        if (starTractor === 3) return 3.60 / (peachPrice / 1000);
        if (starTractor === 4) return 4.20 / (peachPrice / 1000);
        if (starTractor === 5) return 5.10 / (peachPrice / 1000);
    });
    let prices = 0;
    for (let val of list_repair_tractor) {
        prices += val
    }

    return prices.toFixed(0);
}



export default { getTractorRepair, getMPriceTractors };