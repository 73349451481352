import * as React from "react";
import Layout from "../components/layouts/Layout";
import MechanicComponent from "../components/Mechanic/Mechanic";
import mechanicbackground from "../assets/img/backgroundSections/mechanicSection.webp";


const Mechanic = () => {
  return (
    <Layout background={mechanicbackground} effects={'blur-sm'}>
      <MechanicComponent />
    </Layout>
  )
}

export default Mechanic;