
import React, { useState, useEffect } from "react";
import day from "../../../assets/img/backgroundTractor/day.webp";
import afternoon from "../../../assets/img/backgroundTractor/afternoon.webp";
import night from "../../../assets/img/backgroundTractor/night.webp";
import dawn from "../../../assets/img/backgroundTractor/dawn.webp";
import evening from "../../../assets/img/backgroundTractor/evening.webp";
import tierOne from "../../../assets/img/waifuTractor/TierOne.webp"
import tierTwo from "../../../assets/img/waifuTractor/TierTwo.webp"
import tierThree from "../../../assets/img/waifuTractor/TierThree.webp"
import tierFour from "../../../assets/img/waifuTractor/TierFour.webp"
import tierFive from "../../../assets/img/waifuTractor/TierFive.webp"

import start1 from "../../../assets/vector/start1.svg"
import start2 from "../../../assets/vector/start2.svg"
import start3 from "../../../assets/vector/start3.svg"
import start4 from "../../../assets/vector/start4.svg"
import start5 from "../../../assets/vector/start5.svg"

import smoke from "../../../assets/img/smokeTractor.gif";
import repairing from "../../../assets/img/backgroundSections/mechanicSection.webp";
import peachIcon from "../../../assets/img/pechicon2.svg";



const TractorCard = ({ setLoading, damanger, element, repairTractor, peachPrice }) => {
    const [status, statusAction] = useState('');
    const [tractorTime, setTractorTime] = useState(null)

    const durability = {
        now: parseInt(((Date.now() / 1000) - element?.builtAt) / 84600),
        complete: parseInt((element?.goodThrough - element?.builtAt) / 84600)
    }


    const getTimeLeft = (seconds) => {


        var numdays = Math.floor(seconds / 86400);
        var numhours = Math.floor((seconds % 86400) / 3600);
        var numminutes = Math.floor(((seconds % 86400) % 3600) / 60);
        //var numseconds = ((seconds % 86400) % 3600) % 60;

        if (numdays >= 1) return numdays + "d " + numhours + "h "

        return numhours + "h " + numminutes + "m ";
    }


    useEffect(() => {
        let timeNow = new Date()
        let secondsLeft = element.brokenUntil - (timeNow.getTime() / 1000)
        let intervalId;


        if (secondsLeft > 0 && damanger) {
            intervalId = setInterval(() => {

                if (secondsLeft > 0) {
                    secondsLeft = secondsLeft - 1;
                    setTractorTime({ id: element.id, secondsLeft: secondsLeft, seconds: getTimeLeft(secondsLeft) })
                }

            }, 1000)
            setTractorTime({ id: element.id, secondsLeft: secondsLeft, seconds: getTimeLeft(secondsLeft) })
        } else setTractorTime(null)

        return () => clearInterval(intervalId)


    }, [element])

    const tractorArray = [tierOne, tierTwo, tierThree, tierFour, tierFive];
    const startArray = [start1, start2, start3, start4, start5];

    const background_time = (() => {
        let hour = (new Date()).getHours();
        if (hour < 4) return night;
        if (hour < 8) return dawn;
        if (hour < 16) return day;
        if (hour < 18) return afternoon;
        if (hour < 20) return evening;
        if (hour <= 24) return night;
    })();

    const handleRepair = () => {
        repairTractor(element).then(() => { statusAction("Recovering"); setLoading(false) }).catch(() => { setLoading(false) })
    }

    const getPricePeach = () => {
        if(parseInt(element.level) === 1) return 2;
        if(parseInt(element.level) === 2) return 3;
        if(parseInt(element.level) === 3) return 3.5;
        if(parseInt(element.level) === 4) return 4;
        if(parseInt(element.level) === 5) return 5;
    }
    return (
        <div className="rounded-b-lg overflow-hidden bg-white shadow-xl w-52">
            <div className="h-52 relative" style={{ backgroundImage: `url(${(tractorTime?.secondsLeft > 43200 && status !== "Recovering") ? background_time : repairing})`, backgroundSize: 'cover', backgroundPosition: 'bottom' }}>
                {(tractorTime?.secondsLeft < 43200 && status !== "Recovering") && <div className="absolute top-2 left-2 p-1 text-sm border-2 rounded-full border-[#AA2E74] z-[25] bg-[#d686b4]">Time left: {tractorTime?.seconds}</div>}


                {/*  <div className="absolute top-2 left-2 p-1 text-sm border-2 rounded-full border-[#AA2E74] z-[25] bg-[#d686b4]">Repairing Time: {tractorTime?.seconds}</div> */}
                <img alt="start-img" src={startArray[element?.level - 1 || 0]} className="absolute top-2 right-2 z-[26] w-8 " />
                <img alt="tractor-img" src={tractorArray[element?.level - 1 || 0]} className="absolute -bottom-8" />
                {(tractorTime?.secondsLeft > 43200 && status !== "Recovering") && <img alt="smoke-img" src={smoke} className="absolute top-0 left-1/2 -translate-x-1/3 h-[52%]" />}
                <div className="absolute bottom-2 right-2 p-1 border-2 rounded-xl border-[#D9A900] bg-[#FFE68D] z-[26]">{durability.now || 0}/{durability.complete || 0}</div>
            </div>
            <div className="text-md font-bold text-center h-16 flex flex-col items-center justify-center">
                <div>{element?.level} Seats</div>
                {/* {(tractorTime?.secondsLeft > 43200 && status !== "Recovering") && <button onClick={handleRepair} className={`rounded-lg text-md text-white bg-[#D9A900] py-1 px-3`} >   <strong>  Repair </strong>  </button>} */}
                {(tractorTime?.secondsLeft > 43200 && status !== "Recovering") &&
                <button className="rounded-full bg-darkPink p-1 pl-2 font-semibold flex items-center" onClick={handleRepair}>
                    <div className="text-white mr-1">Repair</div>
                    <div className="rounded-full px-2 bg-white text-darkPink flex items-center">
                        <span>{getPricePeach()}</span>
                        <img src={peachIcon} className="h-4 ml-1" />
                    </div>
                </button>}
            </div>
        </div>
    )
}

export default TractorCard;